import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Container, Row, Col } from "reactstrap";
import Button from "reactstrap/lib/Button";

import S7 from "./svgs/s7";
import S8 from "./svgs/s8";
import S11 from "./svgs/s11";
import S1 from "./svgs/s1";

function TextGrid() {
  let pageHeader = React.createRef();
  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
      ? true
      : false;

  const content = [
    {
      title: "Beyond Boundaries Financial Ecosystem",
      description:
        "Exploring the evolution of the financial sector aligned with regional objectives.",
      image: <S8 />,
    },
    {
      title: "Fusion of Finance and Technology",
      description:
        "Democratising access, enhancing transparency, and revolutionising user experiences.",
      image: <S7 />,
    },
    {
      title: "Data Revolution",
      description:
        "Leveraging data for informed decision-making, personalised experiences, and operational efficiency.",
      image: <S11 />,
    },
    {
      title: "Regulatory Agility Framework",
      description:
        "Navigating the regulatory environment to foster innovation while ensuring consumer protection and stability.",
      image: <S1 />,
    },
  ];

  return (
    <>
      <div
        style={{
          background: "transparent",
        }}
        className="section"
        data-parallax={true}
        ref={pageHeader}
      >
        <Container>
          <Row>
            <Col lg={12}>
              <h1
                className=" text-700 pb-0 text-white mt-0 text-uppercase"
                style={{ fontSize: size ? "2rem" : "1.5rem" }}
              >
                <b>Beyond Boundaries:</b>
              </h1>
              <h1
                className=" text-400 pb-0 text-white mt-0 text-uppercase"
                style={{ fontSize: size ? "2rem" : "1.5rem" }}
              >
                Reinventing Finance Through HyperConnected Ecosystems in the Middle East
              </h1>
            </Col>
            <Col lg={6} md={6} sm={12} className="">
              <h5
                className="text-400 text-white text-justify"
                style={{
                  lineHeight: "30px",
                  color: "#010011",
                  textTransform: "none",
                }}
              >
                <br />
                <br />
                In an era marked by rapid technological progress and evolving socio-economic
                landscapes, the Middle East is experiencing a profound transformation in its
                financial sector. Traditional banking models, bound by physical branches and
                bureaucratic processes, are giving way to a new paradigm where finance and
                technology converge, creating unprecedented connectivity and innovation.
                <br />
                <br />
                <b style={{ fontSize: 25, color: "#eb6700" }}>Theme: Beyond Boundaries</b>
                <br />
                <br />
                This year, Finnovex Middle East returns for its 27th edition and 6th annual event in
                2024, under the theme "Beyond Boundaries." The event will explore how the Middle
                East is leading the charge in transforming finance through a hyperconnected
                ecosystem. This flagship event will gather the brightest minds in the financial
                industry to discuss, innovate, and strategize towards building a prosperous and
                thriving financial landscape in the region.
                <br />
                <br />
                <b style={{ fontSize: 25, color: "#eb6700" }}>
                  Revolutionising the Hyperconnected Ecosystem in the Middle East
                </b>
                <br />
                <br />
                Finnovex Middle East 2024 will highlight the significant transformation in the
                financial sector, driven by technological advancements, strategic collaborations,
                and a focus on inclusivity. The event will showcase how finance meets the future in
                the world's most dynamic region, emphasising the transformative potential of a
                hyperconnected ecosystem.
                <br />
                <br />
                <Button className="px-3 py-2  my-2 rounded-0" color="primary" href="/about">
                  <p className="m-0 text-700" style={{ color: "#fff" }}>
                    Know More
                  </p>
                </Button>
              </h5>
            </Col>
            <Col lg={6} className="mt-5 pr-0">
              {content.map((s, index) => (
                <ScrollAnimation
                  animateIn={"fadeInUp"}
                  animateOnce={true}
                  duration={0.5 + index / 8}
                >
                  <div className="py-4 px-1 stat-div mb-2" style={{ minHeight: 200 }}>
                    <div className="container-fluid row pr-0">
                      <Col className="align-self-center" xs={4}>
                        {s.image}
                      </Col>
                      <Col>
                        <h3
                          style={{
                            color: "#fff",
                            fontSize: size ? "18px" : "14px",
                          }}
                          className="text-700 mt-0"
                        >
                          {s.title}
                        </h3>
                        <h3
                          style={{
                            color: "#fff",
                            fontSize: size ? "16px" : "12px",
                          }}
                          className="text-400 mt-0"
                        >
                          {s.description}
                        </h3>
                      </Col>
                    </div>
                  </div>
                </ScrollAnimation>
              ))}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default TextGrid;
