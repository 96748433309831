import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "reactstrap";

import { size } from "./common";
import Slider from "react-slick";
import anime from "animejs";
import S1 from "./svgs/s1";
import ScrollAnimation from "react-animate-on-scroll";
import S2 from "./svgs/s2";
import S3 from "./svgs/s3";
import S4 from "./svgs/s4";
import S5 from "./svgs/s5";
import S6 from "./svgs/s6";
import S7 from "./svgs/s7";
import S8 from "./svgs/s8";
import S9 from "./svgs/s9";
import S10 from "./svgs/s10";
import S11 from "./svgs/s11";

function Spotlight() {
  let pageHeader = React.createRef();
  const [active, setActive] = useState(false);

  const settings = {
    dots: true,
    autoplay: true,
    arrows: true,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
  };

  const content = [
    {
      title: "Holistic Journey-Driven Digital Transformation",
      description: "",
      Image: <S10 active={active} />,
    },
    {
      // new
      title: "The GenAI Revolution in Financial Services",
      description: "",
      Image: <S11 active={active} />,
    },
    {
      title: "Pioneering Trends in Banking and Finance",
      description: "",
      Image: <S1 active={active} />,
    },
    {
      title: "Cloud-Native Banking",
      description: "",
      Image: <S4 active={active} />,
    },
    {
      title: "Open Banking Ecosystems",
      description: "",
      Image: <S3 active={active} />,
    },
    {
      title: "The Rise of Super-Apps",
      description: "",
      Image: <S5 active={active} />,
    },

    {
      title: "Data Privacy and Regulatory Compliance",
      description: "",
      Image: <S2 active={active} />,
    },
    {
      title: "Real-Time Payments Industry Reshaping",
      description: "",
      Image: <S8 active={active} />,
    },
    {
      title: "Agile Digital Lending Infrastructure",
      description: "",
      Image: <S6 active={active} />,
    },

    {
      title: "Enhancing Business Outcomes through Generative AI",
      description: "",
      Image: <S7 active={active} />,
    },
  ];

  return (
    <>
      <div
        style={{
          background: "transparent",
        }}
        className="section"
        data-parallax={true}
        ref={pageHeader}
      >
        {/* <div className="overlay-primary" /> */}
        <Container>
          <Row className="justify-content-center">
            <Col lg={12} className="p-4">
              <ScrollAnimation
                animateIn={"fadeInUp"}
                animateOnce={true}
                duration={1.5}
                // afterAnimatedIn={function afterAnimatedIn(v) {
                //   setActive(true);
                // }}
              >
                <h1 className="text-700 text-center text-white mt-0 text-uppercase">
                  Spotlight Topics
                  <br />
                </h1>
              </ScrollAnimation>
              <br />
            </Col>
          </Row>
          <div className={`justify-content-center row ${size ? "row-cols-5" : "row-cols-2"}`}>
            {content.map((s, index) => (
              <Col className="text-center  p-2">
                <ScrollAnimation
                  animateIn={"fadeInUp"}
                  animateOnce={true}
                  duration={0.5 + index / 8}
                  afterAnimatedIn={function afterAnimatedIn(v) {
                    setActive(true);
                  }}
                >
                  <div className="pt-4 px-2 stat-div" style={{ minHeight: 270 }}>
                    {s.Image}

                    <h3
                      style={{
                        color: "#fff",
                        fontSize: size ? "18px" : "16px",
                      }}
                      className="text-700 mt-4"
                    >
                      {s.title}
                    </h3>
                    <h3
                      style={{
                        color: "#fff",
                        fontSize: size ? "16px" : "14px",
                      }}
                      className="text-400 mt-0"
                    >
                      {s.description}
                    </h3>
                  </div>
                </ScrollAnimation>
              </Col>
            ))}
          </div>
        </Container>
      </div>
      <div
        style={{
          background: "transparent",
        }}
        className="section"
        data-parallax={true}
        ref={pageHeader}
      >
        <Container>
          <Row style={{}} className="justify-content-center">
            <Col lg={12} className="p-4">
              <h1 className="text-700 text-center text-white mt-0 text-uppercase">Trusted by</h1>
              <h5 className="text-400 mt-0 text-white text-center">
                We host some of the community’s most successful and influential organizations and
                solutions providers.
              </h5>
            </Col>
          </Row>
          {size ? (
            <Row className={`text-700 justify-content-center `} style={{ marginTop: "-1rem" }}>
              {sponsors.map((data, i) => (
                <Col className={"mx-auto"} key={i} xs={6} lg={2}>
                  <div
                    style={{
                      display: "flex",
                      aspectRatio: "2/1",
                      justifyContent: "center",
                      background: "#fff",
                      padding: "1em",
                    }}
                    className="stat-div"
                  >
                    <img
                      src={require(`assets/images/sponsors/${data.image}`)}
                      style={{
                        maxWidth: "100%",
                        alignSelf: "center",
                      }}
                      alt={data.name}
                    />
                  </div>
                </Col>
              ))}
            </Row>
          ) : (
            <Row className={`text-700 justify-content-center `} style={{ marginTop: "1rem" }}>
              <Col sm={11} xs={11} className={"ml-auto mr-auto"}>
                <Slider {...settings}>
                  {sponsors.map((data, i) => (
                    <div
                      style={{
                        justifyContent: "center",
                        // background: "#fff",
                        padding: "1em",
                      }}
                    >
                      <img
                        src={require(`assets/images/sponsors/${data.image}`)}
                        style={{
                          width: "100%",
                          // height: "150px",
                          alignSelf: "center",
                        }}
                        alt={data.name}
                      />
                    </div>
                  ))}
                </Slider>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </>
  );
}

export default Spotlight;

export const sponsors = [
  { image: "microsoft.png" },
  { image: "hcl.jpg" },

  { image: "infosys.jpg" },

  { image: "tata.jpeg" },
  { image: "oracle.jpeg" },
  { image: "pwc.jpg" },
];
