import React from "react";

import { Container, Row, Col, Button } from "reactstrap";
import Countries from "./Countries";

import S8 from "./svgs/s8";
import S1 from "./svgs/s1";
import ScrollAnimation from "react-animate-on-scroll";
import Slider from "react-slick";
import { size } from "./common";
import S11 from "./svgs/s11";
import S7 from "./svgs/s7";

function Speaker({ speakers }) {
  const content = [
    {
      title: "Digital Banking and Transaction: Pioneering Financial Evolution",
      description:
        "In an era of digital transformation, 'Digital Banking and Payments' have emerged as critical elements in reshaping the financial landscape. Delve into the significant impact of digitalization on banking services and payment systems. We will explore the rapid evolution of online banking platforms, mobile payment solutions, and the growing influence of cryptocurrencies.",
      image: <S8 />,
    },
    {
      title: "Collaboration for Success:  Fintech-Bank Partnerships Driving Innovation",
      description:
        " Illuminates the transformative potential of collaborative endeavours between fintech and banking institutions. In an era defined by rapid technological advancement and evolving customer expectations, this session explores how strategic partnerships can catalyze innovation and drive meaningful change within the financial services industry.",
      image: <S7 />,
    },
    {
      title: "Cyber Resilience: Safeguarding Digital Trust in an Era of Threats",
      description:
        "In an interconnected world driven by digital innovation, the banking sector faces unprecedented cybersecurity challenges. delve into the evolving landscape of cybersecurity and explore strategies for banks to fortify their defenses, mitigate risks, and safeguard digital trust.",
      image: <S11 />,
    },
    {
      title: "Data Frameworks and Digital Compliance Leadership",
      description:
        "Data and regulators often play a crucial role by creating an enabling regulatory environment that encourages financial institutions to reach underserved populations. Explores the vital role of regulatory adherence and compliance leadership in the digital banking landscape. ",
      image: <S1 />,
    },
  ];
  const content1 = [
    {
      title: "Networking",
      description:
        "Connect with influential thought leaders, industry veterans, and innovators to discuss and refine ideas.",
    },
    {
      title: "Exclusive Roundtable Break-out Sessions",
      description:
        "Small group discussions led by expert facilitators, offering dynamic idea exchange.",
    },
  ];

  const settings = {
    dots: true,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 3000,
    swipeToSlide: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    rows: 1,
    className: "p-10",
    customPaging: function (i) {
      return <div className="dot"></div>;
    },
    dotsClass: "slick-dots slick-thumb",
  };
  return (
    <>
      <div
        style={{
          backgroundColor: "transparent",

          overflow: "hidden",
        }}
        className="section py-3"
      >
        <Container
          fluid
          style={{
            backgroundRepeat: "no-repeat",
            backgroundPosition: "top right",

            backgroundSize: "80%",
          }}
        >
          <Container className="py-2">
            <Row>
              <Col lg={12}>
                <h1
                  className=" text-700 pb-0 text-white mt-0 text-uppercase"
                  style={{ fontSize: size ? "2rem" : "1.5rem" }}
                >
                  <b>Beyond Boundaries:</b>
                </h1>
                <h1
                  className=" text-400 pb-0 text-white mt-0 text-uppercase"
                  style={{ fontSize: size ? "2rem" : "1.5rem" }}
                >
                  Reinventing Finance Through HyperConnected Ecosystems in the Middle East
                </h1>
              </Col>
              <Col lg={6} md={6} sm={12} className="">
                <h5
                  className="text-400 text-white text-justify"
                  style={{
                    lineHeight: "30px",
                    color: "#010011",
                    textTransform: "none",
                  }}
                >
                  <br />
                  <br />
                  In an era marked by rapid technological progress and evolving socio-economic
                  landscapes, the Middle East is experiencing a profound transformation in its
                  financial sector. Traditional banking models, bound by physical branches and
                  bureaucratic processes, are giving way to a new paradigm where finance and
                  technology converge, creating unprecedented connectivity and innovation.
                  <br />
                  <br />
                  <b style={{ fontSize: 25, color: "#eb6700" }}>Theme: Beyond Boundaries</b>
                  <br />
                  <br />
                  This year, Finnovex Middle East returns for its 27th edition and 6th annual event
                  in 2024, under the theme "Beyond Boundaries." The event will explore how the
                  Middle East is leading the charge in transforming finance through a hyperconnected
                  ecosystem. This flagship event will gather the brightest minds in the financial
                  industry to discuss, innovate, and strategize towards building a prosperous and
                  thriving financial landscape in the region.
                  <br />
                  <br />
                  <b style={{ fontSize: 25, color: "#eb6700" }}>
                    Revolutionising the Hyperconnected Ecosystem in the Middle East
                  </b>
                  <br />
                  <br />
                  Finnovex Middle East 2024 will highlight the significant transformation in the
                  financial sector, driven by technological advancements, strategic collaborations,
                  and a focus on inclusivity. The event will showcase how finance meets the future
                  in the world's most dynamic region, emphasising the transformative potential of a
                  hyperconnected ecosystem.
                  <br />
                  <br />
                  <Button className="px-3 py-2  my-2 rounded-0" color="primary" href="/about">
                    <p className="m-0 text-700" style={{ color: "#fff" }}>
                      Know More
                    </p>
                  </Button>
                </h5>
              </Col>
              <Col lg={6} className="mt-5 pr-0">
                {content.map((s, index) => (
                  <ScrollAnimation
                    animateIn={"fadeInUp"}
                    animateOnce={true}
                    duration={0.5 + index / 8}
                  >
                    <div className="py-4 px-2 stat-div mb-2" style={{ minHeight: 200 }}>
                      <div className="container-fluid row pr-0">
                        <Col className="align-self-center" xs={3}>
                          {s.image}
                        </Col>
                        <Col>
                          <h3
                            style={{
                              color: "#fff",
                              fontSize: size ? "18px" : "14px",
                            }}
                            className="text-700 mt-0"
                          >
                            {s.title}
                          </h3>
                          <h3
                            style={{
                              color: "#fff",
                              fontSize: size ? "16px" : "12px",
                            }}
                            className="text-400 mt-0"
                          >
                            {s.description}
                          </h3>
                        </Col>
                      </div>
                    </div>
                  </ScrollAnimation>
                ))}
              </Col>
            </Row>
          </Container>
          <Container>
            <Row>
              <Col lg={12}>
                <h3
                  style={{
                    color: "#fff",
                    fontSize: size ? "36px" : "20px",
                  }}
                  className="text-400 mt-0"
                >
                  Networking and Insights
                </h3>
              </Col>
            </Row>
            <Slider {...settings}>
              {content1.map((data, i) => (
                <div className="d-flex w-100 my-5">
                  <Col lg={6} className="align-self-start">
                    <h3
                      style={{
                        color: "#fff",
                        fontSize: "36px",
                      }}
                      className="text-400 mt-0"
                    >
                      <i class="fa fa-quote-left text-primary" aria-hidden="true"></i>{" "}
                      <span className="pl-4 pr-4">{data.title}</span>
                      <i class="fa fa-quote-right text-primary" aria-hidden="true"></i>
                    </h3>
                    <h4 className="text-400 mt-5 text-white">{data.description}</h4>
                  </Col>
                  <Col lg={6} className="align-self-center">
                    <img src={require(`assets/quotes/${i + 5}.jpg`)} width="100%" alt="main logo" />
                  </Col>
                </div>
              ))}
            </Slider>
          </Container>

          <Countries />
          <Container>
            <Row className="justify-content-center">
              <Col lg={4} className="text-center">
                <Button
                  href="/register"
                  className="btn my-2 text-center px-5"
                  color="primary"
                  size="lg"
                  outline
                >
                  Register Now
                </Button>
              </Col>
            </Row>
          </Container>
          <Container className="py-5">
            <Row className="justify-content-center">
              {images.map((id, i) => (
                <Col lg={4} xs={12} key={i}>
                  <img
                    alt="..."
                    className=" img-responsive my-3"
                    width="100%"
                    src={require(`assets/images/photos/${id}`)}
                  />
                </Col>
              ))}
            </Row>
          </Container>
        </Container>
      </div>
    </>
  );
}

export default Speaker;

const images = [
  "DSC00068.jpg",
  "DSC00080.jpg",
  "DSC00097.jpg",
  "DSC00777.jpg",
  "DSC08476.jpg",
  "DSC08652.jpg",
  "DSC08667.jpg",
  "DSC08670.jpg",
  "DSC08711.jpg",
  "DSC08716.jpg",
  "DSC09944.jpg",
  "DSC09999.jpg",
];
