import React, { useEffect } from "react";
import anime from "animejs";

export default function S3({ active }) {
  // const size = document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024 ? true : false;
  function animation() {
    anime({
      targets: ".s3",
      strokeDashoffset: [anime.setDashoffset, 0],
      easing: "cubicBezier(.5, .05, .1, .3)",
      duration: 8000,
      delay: function (el, i) {
        return i * 50;
      },
      loop: true,
      direction: "alternate",
    });
  }

  useEffect(() => {
    animation();
  }, []);
  return (
    <div
      style={
        {
          // width: 100,
        }
      }
      className="mx-auto mb-2"
      id="svg"
    >
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 46 46"
        style={{
          //   fill: "rgba(255,255,255,0.1)",
          stroke: "#eb6700",
          strokeWidth: 0.4,
          fill: "none",
          strokeMiterlimit: 10,
        }}
      >
        <path
          class="s3"
          d="M2.92,15.33a2.2,2.2,0,0,1,1.46-2.06V24.83H5.84V6.57A2.19,2.19,0,0,1,8,4.38H27.75V2.92H8A3.66,3.66,0,0,0,4.38,6.57v5.19a3.65,3.65,0,0,0-2.92,3.57V24.1H2.92Z"
        />
        <path
          class="s3"
          d="M42.35,40.89a1.47,1.47,0,0,1-1.46,1.46h-38a1.46,1.46,0,0,1-1.46-1.46V39.43h14.3a1.76,1.76,0,0,1,1.25.51,3.19,3.19,0,0,0,2.27,1h5.25a3.19,3.19,0,0,0,2.27-1,1.76,1.76,0,0,1,1.25-.51h14.3ZM43.08,38h-.73V22.63H40.89V38H28.05a3.22,3.22,0,0,0-2.28.94,1.77,1.77,0,0,1-1.25.52H19.29A1.77,1.77,0,0,1,18,38.91,3.22,3.22,0,0,0,15.76,38H2.92V35.05H1.46V38H.73A.73.73,0,0,0,0,38.7v2.19a2.92,2.92,0,0,0,2.92,2.92h38a2.92,2.92,0,0,0,2.92-2.92V38.7a.73.73,0,0,0-.73-.73"
        />
        <rect class="s3" x="37.97" y="22.63" width="1.46" height="13.87" />
        <rect class="s3" x="4.38" y="35.05" width="1.46" height="1.46" />
        <rect class="s3" x="8.03" y="5.84" width="1.46" height="1.46" />
        <rect class="s3" x="10.95" y="5.84" width="1.46" height="1.46" />
        <rect class="s3" x="13.87" y="5.84" width="1.46" height="1.46" />
        <rect class="s3" x="8.03" y="8.76" width="18.25" height="1.46" />
        <path
          class="s3"
          d="M19.27,13.89a7.31,7.31,0,0,1,7.24.67l.84-1.2a8.75,8.75,0,0,0-10.08,0l.85,1.2a6.91,6.91,0,0,1,1.15-.67"
        />
        <path
          class="s3"
          d="M26.72,36.83A18.11,18.11,0,0,0,30.48,31l-1.35-.55a16.57,16.57,0,0,1-3.47,5.34Z"
        />
        <path
          class="s3"
          d="M42.35,19.71H29.21V10.22H42.35ZM35.78,2.92A3.65,3.65,0,0,0,32.2,5.84H30.72a5.11,5.11,0,0,1,10.17.73V8.76H39.43V6.57a3.66,3.66,0,0,0-3.65-3.65m7.3,5.84h-.73V6.57a6.57,6.57,0,1,0-13.14,0,.72.72,0,0,0,.73.73h2.92a.73.73,0,0,0,.73-.73,2.19,2.19,0,0,1,4.38,0V8.76H28.48a.73.73,0,0,0-.73.73v6.64a12.91,12.91,0,0,0-6-1.44h-.1a12.06,12.06,0,0,0-6.83,2.11l.82,1.2a10.71,10.71,0,0,1,6-1.85h.1a11.31,11.31,0,0,1,6,1.66v1.76a10,10,0,0,0-6-2h-.1a9.36,9.36,0,0,0-7.66,4l-.35.51,1.2.83.35-.5a7.91,7.91,0,0,1,6.46-3.41h.1a8.18,8.18,0,0,1,6.71,3.34,7.73,7.73,0,0,1,1.14,2.41,7.58,7.58,0,0,1,0,4.17l1.41.4a9.09,9.09,0,0,0,0-5,9,9,0,0,0-1.36-2.86c-.09-.14-.19-.27-.29-.4H43.08a.73.73,0,0,0,.73-.73v-11a.73.73,0,0,0-.73-.73"
        />
        <path
          class="s3"
          d="M33.71,16.79A2.16,2.16,0,0,1,35,15.5a.73.73,0,0,0,.4-.95.67.67,0,0,0-.12-.2.72.72,0,0,1-.18-.48.73.73,0,1,1,1.46,0,.75.75,0,0,1-.18.48.73.73,0,0,0,.07,1,.82.82,0,0,0,.2.12,2.16,2.16,0,0,1,1.24,1.29Zm4.15-2.26a2.08,2.08,0,0,0,.11-.66,2.19,2.19,0,1,0-4.38,0,2.07,2.07,0,0,0,.1.66,3.61,3.61,0,0,0-1.56,3,.72.72,0,0,0,.73.73H38.7a.73.73,0,0,0,.73-.73,3.62,3.62,0,0,0-1.57-3"
        />
        <path
          class="s3"
          d="M1.46,27.75h19v4.38h-19Zm20.22-2.84h.38a2,2,0,0,1,1.64.84A1.92,1.92,0,0,1,24,27.5a10.73,10.73,0,0,1-2.1,3.78V28.7a9.24,9.24,0,0,0,.79-2,.18.18,0,0,0-.13-.22h0l-1.38-.23H19.78a2,2,0,0,1,1.9-1.38M.73,33.59h14.8l-.09.09,1,1,1.12-1.12h2.06l-2,2,1,1L22.31,33a12.43,12.43,0,0,0,3.08-5.05,3.39,3.39,0,0,0-.51-3.05,3.48,3.48,0,0,0-2.82-1.44h-.38a3.53,3.53,0,0,0-3.3,2.42c-.05.14-.1.28-.16.42H16.55a3.79,3.79,0,0,0,.45-.9A5,5,0,0,1,21.68,22h.38a4.94,4.94,0,0,1,4,2,4.85,4.85,0,0,1,.73,4.37A13.92,13.92,0,0,1,23.34,34l-2.69,2.69,1,1,2.7-2.69a15.57,15.57,0,0,0,3.79-6.24,6.29,6.29,0,0,0-.94-5.67,6.44,6.44,0,0,0-5.17-2.62h-.38a6.45,6.45,0,0,0-6.06,4.39,2.22,2.22,0,0,1-.53.85l-.52.52H.73A.73.73,0,0,0,0,27v5.84A.73.73,0,0,0,.73,33.59Z"
        />
        <rect class="s3" x="2.92" y="29.21" width="1.46" height="1.46" />
        <rect class="s3" x="5.84" y="29.21" width="1.46" height="1.46" />
        <rect class="s3" x="8.76" y="29.21" width="1.46" height="1.46" />
        <rect class="s3" x="11.68" y="29.21" width="1.46" height="1.46" />
      </svg>
    </div>
  );
}
