export const sponsors = [
  {
    image: "backbase.png",
    type: "OFFICIAL ENGAGEMENT BANKING SPONSOR",
    grid: 12,
    smallGrid: 12,
    link: "https://www.backbase.com/",
  },
  {
    image: "threatLocker.jpg",
    type: "PLATINUM SPONSOR",
    grid: 12,
    smallGrid: 12,
    link: "https://threatlocker.com/",
  },
  {
    image: "kissflow.jpg",
    type: "GOLD SPONSOR",
    grid: 12,
    smallGrid: 12,
    link: "https://kissflow.com/",
  },

  {
    image: "infosys.jpg",
    type: "STRATEGIC PARTNER ",
    grid: 12,
    smallGrid: 12,
    link: "https://www.edgeverve.com/finacle/",
    a: 6,
  },
  {
    image: "azentio.jpg",
    type: "OFFICIAL TECHNOLOGY PARTNER ",
    grid: 12,
    smallGrid: 12,
    link: "https://www.azentio.com/",
    a: 6,
  },
  {
    image: "comforte_logo.png",
    type: "SILVER SPONSORS",
    grid: 4,
    smallGrid: 4,
    link: "https://www.comforte.com/",
  },
  {
    image: "Concerto-Logo.png",
    type: "SILVER SPONSORS",
    grid: 4,
    smallGrid: 4,
    link: "https://www.concertosoft.com/",
  },
  {
    name: "Neosoft",
    image: "neosoft.png",
    type: "NETWORKING SPONSOR",
    link: "http://www.neosofttech.com/",
    grid: 6,
    text: "NeoSOFT is an SEI-CMMI Level-5 and ISO 9001:2008 certified global IT consulting & software solutions provider with 2000+ software consultants working full time across 7 Delivery Centers. Established in 1996, NeoSOFT is headquartered in Mumbai, India with offices in the USA, UK, Dubai, Italy, Germany, Japan & Australia. Our commitment to quality and 23+ years of experience has made us serve over 1500+ clients across 50+ countries with 85% client retention. They build cohesive technology solutions for the World’s Leading Enterprises, SMEs & Startups and ISV's We cater to more than 22+ Industries like  Banking & Finance - E-Commerce & Retail - Education & E-Learning - Government/ Semi-Government Healthcare - Insurance - IT & Consulting - Manufacturing - Media & Entertainment to name a few.",
  },
  {
    image: "microsoft.png",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "https://www.microsoft.com/",
  },
  {
    image: "oracle.jpeg",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "https://www.oracle.com/in/index.html",
  },
  {
    image: "pwc.jpg",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "https://www.pwc.in/",
  },
  {
    image: "EY.jpeg",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "https://www.ey.com/en_in",
  },
  {
    image: "tcs.jpg",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "https://www.tcs.com/",
  },
  {
    image: "expo2020.png",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "https://www.expo2020dubai.com/",
  },
  {
    image: "edb.png",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "https://www.bahrainedb.com/",
  },
  {
    image: "difc.jpg",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "https://www.difc.ae/",
  },

  {
    image: "oxford.png",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "",
  },
  {
    image: "rakbank.png",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "https://www.pwc.in/",
  },

  {
    image: "smartstream.png",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "https://www.smartstream-stp.com/",
  },
  {
    image: "temenos.jpg",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "https://www.temenos.com/",
  },
  {
    image: "avaya.png",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "https://www.avaya.com/en/",
  },

  {
    image: "bankserv.jpg",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "https://www.bankservafrica.com/website/",
  },
  {
    image: "beingcrypto.png",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "https://beincrypto.com/",
  },
  {
    image: "comviva.png",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "https://www.comviva.com/",
  },
  {
    image: "consdata.jpeg",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "https://www.consdata.com/en/",
  },
  {
    image: "daon.jpg",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "https://www.daon.com/",
  },
  {
    image: "darktrace.png",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "darktrace",
  },

  {
    image: "fireeye.png",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "https://www.fireeye.com/",
  },
  {
    image: "freshworks.png",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "https://www.freshworks.com/",
  },
  {
    image: "fss.png",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "https://www.fsstech.com/",
  },
  {
    image: "iib.jpeg",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "https://iib.gov.in/",
  },
  {
    image: "infobip.jpeg",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "https://www.infobip.com/",
  },
  {
    image: "infrasoft.png",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "https://www.infrasofttech.com/",
  },
  {
    image: "ManageEngine.png",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "https://www.manageengine.com/",
  },

  {
    image: "newgen.png",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "https://newgensoft.com/home-us/",
  },
  {
    image: "onespan.jpg",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "https://www.onespan.com/",
  },

  {
    image: "piotech.png",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "https://www.oracle.com/in/index.html",
  },

  {
    image: "recorded.png",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "https://www.recordedfuture.com/",
  },
  {
    image: "scventure.jpg",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "https://scventures.io/",
  },

  {
    image: "smartmessage.png",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "https://www.smartmessage.com/",
  },

  {
    image: "turbonomic.png",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "https://www.turbonomic.com/",
  },
  {
    image: "yethi.png",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "https://yethi.in/",
  },
  {
    name: "network international",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "",
    image: "network.jpg",
  },
  {
    name: "arabnet",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "",
    image: "arabnet.jpeg",
  },
  {
    name: "triwire",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "",
    image: "tripwire.jpeg",
  },
  {
    name: "fintechgalaxy",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "",
    image: "fintechgalaxy.jpeg",
  },
  {
    name: "creatio ",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "",
    image: "creatio.png",
  },
  {
    name: "confluence",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "",
    image: "confluent.jpg",
  },
  {
    name: "abaka",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "",
    image: "abaka.jpg",
  },
  {
    name: "payoneer ",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "",
    image: "Payoneer.jpeg",
  },
  {
    name: "Unlockbc",
    type: "Media Partner",
    grid: 3,
    link: "http://www.unlock-bc.com/",
    image: "unlock.png",
  },
];

export const groupBy = (key) => (array) =>
  array.reduce((objectsByKeyValue, obj) => {
    const value = obj[key];
    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
    return objectsByKeyValue;
  }, {});

const groupByType = groupBy("type");
export const sponsorsPage = groupByType(sponsors);

export const speakers = [
  {
    name: "Sean Stander",
    title: "Chief Operating Officer Risk",
    company: "Standard Bank Group, South Africa",
    image: "Sean Stander.jpg",
    companyImage: "sb.png",
  },

  {
    name: "Kuldip Paliwal",
    title: "Managing Director",
    company: "First Alliance Bank, Zambia",
    image: "Kuldip.jpg",
    companyImage: "alliance.png",
    link: "",
  },
  {
    name: "Chresta C. Kaluba",
    title: "Chief Risk Officer ",
    company: "Atlas Mara, Zambia",
    image: "Chresta Pic.jpg",
    companyImage: "bancABC.jpg",
  },
  {
    name: "Francis Karuhanga ",
    title: "Chief Audit Officer ",
    company: "Standard Bank Group, South Africa",
    image: "Francis Karuhanga.jpg",
    companyImage: "sb.png",
  },
  // {
  //   name: 'Robert Mookola Malasha ',
  //   title: 'Acting Chief Financial Officer ',
  //   company: 'Development Bank, Zambia',
  //   image: 'Robert Mookola.jpg',
  //   companyImage: undefined,
  // },
  {
    name: "Krishnan Menon",
    title: "Chief Operating Officer",
    company: "Absa Bank of Botswana, South Africa",
    companyImage: "absa.png",
    image: "krishnan.jpg",
  },
  {
    name: "Clifford Niemand",
    title: "Chief Operations Director",
    company: "KnoxWire",
    companyImage: "knoxwire.png",
    image: "Clifford (3).jpg",
  },

  {
    name: "Rajal Vaidya",
    title: "Chief Risk Officer- Corporate & Investment Bank",
    company: "Absa Group, South Africa",
    companyImage: "absa.png",
    image: "Rajal Vaidya.jpg",
  },

  {
    name: "Benedicto Baragomwa",
    title: "Chief Internal Auditor",
    company: "NMB Bank Plc, Tanzania",
    companyImage: "nmb.jpg",
    image: "Benedicto Baragomwa.jpg",
  },
  // {
  //   name: 'Yaza Kaluwa Kara ',
  //   title: 'Head: Innovation Partnerships & Agile Project Office ',
  //   company: 'Stanbic Bank, Zambia ',
  //   image: 'Yaza Kaluwa Kara.jpg',
  //   companyImage: 'sb.png',
  // },
  {
    name: "Dr. Lance Mambondiani",
    title: "Managing Director & CEO",
    company: "BancABC, Zimbabwe",
    image: "Dr Lance - Picture.jpg",
    companyImage: "bancABC.jpg",
    link: "https://www.linkedin.com/in/lance-mambondiani-32452055/",
  },
  // {
  //   name: 'Moipone Phajane',
  //   title: 'Head of Strategy',
  //   company: 'TYME, South Africa ',
  //   image: 'Moipone Phajane.jpg',
  //   companyImage: 'tyme.jpg',
  // },
  // {
  //   name: 'Sibonile Moyo ',
  //   title: 'Head of Legal Investments ',
  //   company: 'Development Bank, South Africa',
  //   image: 'Sibonile Moyo.jpg',
  //   companyImage: undefined,
  // },

  {
    name: "Shillan Kisten ",
    title: "Head: Innovation and Architecture",
    company: "Old Mutual Insure, South Africa",
    image: "Shillan Kisten.jpg",
    companyImage: undefined,
  },

  // {
  //   name: 'Aluwani Thenga ',
  //   title: 'Head: Business Development Strategy',
  //   company: 'RMB Corporate & Investment Banking, South Africa',
  //   image: 'Aluwani Thenga.jpg',
  //   companyImage: 'rmb.png',
  // },
  // {
  //   name: 'Solomon Sikhosana',
  //   title: 'Chief Credit Officer ',
  //   company: 'Absa Group, South Africa',
  //   image: 'Solomon Sikhosana.jpg',
  //   companyImage: 'absa.png',
  // },

  {
    name: "Prenton Chetty",
    title: "Head: Intelligent Systems",
    company: "Nedbank, South Africa ",
    image: "Prenton Chetty.jpg",
    companyImage: "ned.png",
  },
  {
    name: "Jacob Basera",
    title: "Head of Digital Innovation,",
    company: "Standard Bank, Moçambique",
    image: "JAcob Baser 2 Pic.jpg",
    companyImage: "sb.png",
  },
  {
    name: "Anita Felbrich-",
    secondName: "Smit",
    title: "Enterprise Architect Credit and Client Risk",
    company: "Freelance South",
    image: "Anita Felbrich-Smit.jpg",
    companyImage: undefined,
  },

  // {
  //   name: 'Mutisunge Zulu ',
  //   title: 'Chief Risk Officer ',
  //   company: 'Zambia National Commercial Bank, Zambia',
  //   image: 'Mutisunge Zulu.jpg',
  //   companyImage: 'zanaco.jpg',
  // },

  // {
  //   name: 'Munya Kavhu',
  //   title: 'Chief Operating Officer Risk',
  //   company: 'Steward Bank, Zimbabwe',
  //   companyImage: 'steward.png',
  //   image: 'Munya Kavhu.jpg',
  // },

  {
    name: "Thapelo Tsheole",
    title: "Chief Executive Officer",
    company: "Botswana Stock Exchange, Botswana",
    companyImage: "bse.jpg",
    image: "Thapelo.jpg",
  },

  {
    name: "Sam Mohatle",
    title: "Head of Digital Banking",
    company: "FNB South Africa, Lesotho",
    companyImage: "fnb.png",
    image: "Sam Mohatle.jpg",
  },

  {
    name: "Pragashani Reddy",
    title: "Head of Technology and Digital Enablement",
    company: "Standard Bank Corporate, South Africa",
    image: "Pragashani Reddy.jpg",
    companyImage: "standard.png",
  },

  {
    name: "Sebestian Abrahams",
    title: "Vice President: Trade and Working Capital Digital Operation",
    company: "Absa Group, South Africa",
    image: "Sebestian Abrahams.jpg",
    companyImage: "absa.png",
  },

  {
    name: "Oswin Zulu",
    title: "Group Chief Digital Officer",
    company: "Zimnat Zimbabwe, South Africa",
    image: "oswin.jpg",
    companyImage: "Zimnat.png",
    link: "https://www.linkedin.com/in/oswin-kanyaka-zulu/?originalSubdomain=zw",
  },

  // {
  //   name: 'Laston Mangala Hamoonga',
  //   title: 'Chief Credit Officer',
  //   company: 'First Capital Bank Zambia',
  //   image: 'Laston Mangala Hamoonga.jpg',
  //   companyImage: 'firstcapital.jpeg',
  // },
  {
    name: "Paul Raskin",
    title: "Director - Business development",
    company: "Trace Financial",
    image: "PaulRuskin.jpg",
    companyImage: "tracefinancialblue 700x250 (1).png",
  },
  {
    name: "Paresh Daya",
    title: "Head: Digital Assets",
    company: "IntellectEU",
    image: "Paresh Daya Profile Pic.png",
    companyImage: "intellect.jpeg",
  },
  {
    name: "Laston Mangala Hamoonga ",
    title: "Chief Credit Officer",
    company: "First Capital Bank Zambia",
    image: "speaker.png",
    companyImage: undefined,
  },
  {
    name: "Dr. Mark Nasila",
    title: "Chief Analytics Officer",
    company: "FNB South Africa, South Africa",
    image: "mark.jpg",
    companyImage: "fnb.png",
  },
  {
    name: "Flynn Robson ",
    title: "Head of Portfolio Management & Stockbroking",
    company: "Sasfin, South Africa",
    image: "Flynn Robson.jpg",
    companyImage: undefined,
  },
  {
    name: "Roanleigh Thambiran,",
    title: "Retail Banking Executive",
    company: "Independent Consultant",
    image: "Roanleigh Thambiran.jpg",
    companyImage: undefined,
  },
  {
    name: "Ravi Moodley",
    title: "Head: Product, Pricing & Channels for Africa",
    company: "Nedbank",
    image: "Ravi Moodley.png",
    companyImage: "ned.png",
  },
  {
    name: "Luntente Ndyamba",
    title: "Director: CIB Service Efficiency & Process Operations",
    company: "Absa Corporate and Investment Banking, South Africa",
    image: "Luntente Ndyamba.jpg",
    companyImage: "absa.png",
  },
  {
    name: "Rob Allen",
    title: "VP of Operations (EMEA) ",
    company: "ThreatLocker",
    image: "Rob Allen.jpg",
    companyImage: "threatLocker.png",
  },
  {
    name: "Alex Page",
    title: "VP Solution Consulting",
    company: "Worldpay from FIS",
    image: "Alex_Page_7.jpg",
    companyImage: "worldpay.png",
  },
  {
    name: "Enoch Malisa",
    title: "Head Merchant Sales & Acquiring",
    company: "Visa",
    image: "Enoch.jpg",
    companyImage: "visa.png",
  },
  {
    name: "Frank Gotthardt",
    title: "Chief Strategy Officer",
    company: "Zentity",
    image: "Frank Gotthardt.jpg",
    companyImage: "zentity.png",
  },
  {
    name: "Vyani Roychoudhury",
    title: "Director, Head of Digital Consulting, Visa Consulting & Analytics",
    company: "Sub-Saharan Africa",
    image: "Vyani Roychoudhury.jpg",
    companyImage: undefined,
  },
  {
    name: "Cheslyn Jacobs",
    title: "Chief Commercial Officer, ",
    company: "TymeBank, South Africa",
    image: "Cheslyn Jacobs.jpg",
    companyImage: "tyme.jpg",
  },

  {
    name: "Nollie Maoto, ",
    title: "Chief Data and Analytics Officer,",
    company: "FNB South Africa",
    image: "Nollie.jpg",
    companyImage: "FBNBank.png",
  },

  {
    name: "Lizzy Mogale, ",
    title: "Managing Executive : Insights and Advisory, Retail and Business Banking, ",
    company: "Nedbank, South Africa",
    image: "Lizzy.jpg",
    companyImage: "ned.png",
  },

  {
    name: "Ahmed Khidhir",
    title: "Business Development Manager - Digital Banking Solutions ",
    company: "Temenos",
    image: "Ahmed Khidhir Profile Picture.jpg",
    companyImage: undefined,
  },

  {
    name: "Dr. Christian Herbert Ayiku,",
    title: "CEO ",
    company: "Dafribank Digital LTD",
    image: "Dr. Christian Herbert Ayiku.png",
    companyImage: undefined,
  },

  {
    name: "Isaac Joseph ",
    title: "Solutions Consultant",
    company: "Worldpay",
    image: "Isaac.JPG",
    companyImage: "worldpay.png",
  },

  {
    name: "Kumaran Selvarajalu",
    title: "Senior General Manager, Payments",
    company: "Banking Association, South Africa",
    image: "kumaran.jpg",
    companyImage: undefined,
    link: "https://www.linkedin.com/in/kselvarajalu/?originalSubdomain=za",
  },
];

export const pastspeakers = [];
