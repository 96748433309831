import React from "react";
import { Container, Row, Col } from "reactstrap";

function WhyAttend() {
  return (
    <>
      <Container>
        <Row>
          <Col lg={11}>
            <h2 className="text-700 text-white mt-5">Event Highlights</h2>
          </Col>
        </Row>
        <Row>
          <Col lg={12} className="text-white mt-5">
            <div className="mb-3">
              <h4 className="text-700 mt-0">Reinventing the Finance Ecosystem</h4>
              <h5 className="text-400 mt-0">
                Exploring the booming FinTech scene and innovations making the financial sector more
                competitive and responsive.
              </h5>
            </div>
            <div className="mb-3">
              <h4 className="text-700 mt-0">Gathering of Visionaries</h4>
              <h5 className="text-400 mt-0">
                Uniting BFSI leaders, entrepreneurs, investors, and government officials globally to
                explore the potential of hyperconnected banking and finance.
              </h5>
            </div>
            <div className="mb-3">
              <h4 className="text-700 mt-0">Innovation Showcase</h4>
              <h5 className="text-400 mt-0">
                Featuring groundbreaking solutions and pioneering startups in digital banking,
                regtech, insurtech, and more.ghlighting its strategic significance in facilitating
                global market access
              </h5>
            </div>
            <div className="mb-3">
              <h4 className="text-700 mt-0">Inspirational Keynotes and Panels</h4>
              <h5 className="text-400 mt-0">
                Engaging presentations and discussions on emerging trends, disruptive technologies,
                and strategic partnerships.
              </h5>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default WhyAttend;

const content = [
  { name: "UAE", image: "uae.png" },
  { name: "KSA", image: "ksa.png" },
  { name: "Qatar", image: "qatar.png" },
  { name: "Israel", image: "israel.jpg" },
  { name: "Bahrain", image: "bahrain.jpg" },
  { name: "Kuwait", image: "kuwait.png" },
  { name: "Oman", image: "oman.jpg" },
  { name: "Lebanon", image: "lebanon.jpg" },
  { name: "Jordan", image: "jordan.png" },
  { name: "Egypt", image: "egypt.jpg" },
];
