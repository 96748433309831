// reactstrap components
import { Container, Col, Row, Button } from "reactstrap";

import React from "react";
import AnimeBackground from "components/Anime";

function LandingPageHeader({ home }) {
  const size =
    document.body.clientWidth >= 928 || document.documentElement.clientWidth >= 928 ? true : false;

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (document.documentElement.scrollTop > 50 || document.body.scrollTop > 50) {
      } else if (document.documentElement.scrollTop < 300 || document.body.scrollTop < 300) {
      }
    };

    window.addEventListener("scroll", updateNavbarColor);

    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });

  return (
    <>
      <div
        className="page-header"
        style={{
          backgroundColor: "transparent",
        }}
        data-parallax={true}
      >
        <Container>
          <Row className="justify-content-start">
            <Col md={5} xs={12}>
              <img src={require("assets/images/logo/me.png")} width="100%" alt="main logo" />
              <h2
                className="text-700 text-white mt-3 mb-2"
                style={{
                  color: "#fff",
                  fontSize: size ? "35px" : "20px",
                }}
              >
                SEPTEMBER <span style={{ color: "#eb6700" }}>9-10, 2025</span>
              </h2>
            </Col>
            <Col md={5} xs={12} className={size ? "border-left" : "border-top"}>
              <h3
                style={{
                  color: "#fff",
                  fontSize: size ? "37px" : "18px",
                  marginTop: size ? 0 : 20,
                }}
                className="text-400 ml-2"
              >
                <i>The Leading Summit on Financial Services Innovation and Excellence</i>
              </h3>
              <h4
                style={{
                  color: "#fff",
                  fontSize: size ? "40px" : "20px",
                }}
                className="text-700 mt-3 ml-2"
              >
                Dubai, UAE
              </h4>
            </Col>
            <Col md={6} xs={12} className="mt-4">
              <Button
                href="/register"
                className="navbar-item-custom text-400 text-dark rounded-0 ml-2"
                color="primary"
                size="lg"
              >
                <span
                  style={{
                    color: "#ffffff",
                    fontSize: "16px",
                  }}
                  className="text-700"
                >
                  REGISTER NOW
                </span>
              </Button>{" "}
              <Button
                href="/sponsor"
                className="navbar-item-custom text-400 text-dark rounded-0"
                size="lg"
                color="primary"
              >
                <span
                  style={{
                    color: "#ffffff",
                    fontSize: "16px",
                  }}
                  className="text-700"
                >
                  BECOME A SPONSOR
                </span>
              </Button>
            </Col>
          </Row>
        </Container>

        <AnimeBackground />
      </div>
    </>
  );
}

export default LandingPageHeader;
